import { isBrowser, isServer } from "utils/next";

declare global {
  interface Window {
    script_load_map: any;
  }
}

export function isMobile(userAgent?: string) {
  if (!userAgent) return viewportWidth() < 768;

  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
}

export function params() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return fromEntries(urlSearchParams.entries());
}

export function isEndOfScroll(scrollEvent) {
  const maxScroll =
    parseInt(scrollEvent.target.scrollHeight) -
    parseInt(scrollEvent.target.offsetHeight);
  const currentScroll = parseInt(scrollEvent.target.scrollTop);

  return currentScroll > maxScroll - 200;
}

export function waitUntilLoaded(variable, executable) {
  if (isLoaded(variable)) {
    executable();
  } else {
    setTimeout(() => {
      waitUntilLoaded(variable, executable);
    }, 300);
  }
}

export function resetScroll() {
  document.querySelector("body").scrollTo(0, 0);
}

export function loadScript(url, options: any = {}) {
  if (scriptHasBeenLoaded(url)) return new Promise((resolve) => resolve(true));

  const loadMap = window.script_load_map;
  if (loadMap && loadMap[url]?.onload) return loadMap[url].onload;

  const tag = document.createElement("script");
  tag.src = url;

  window.script_load_map = window.script_load_map || {};
  window.script_load_map[url] = {
    onload: new Promise((resolve, _reject) => {
      const { async, defer, crossOrigin } = options;
      if (async) tag.async = true;
      if (defer) tag.defer = true;
      if (crossOrigin) tag.crossOrigin = crossOrigin;
      tag.onload = resolve;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    })
  };

  return window.script_load_map[url].onload;
}

export function isPwa(): boolean {
  return (
    isBrowser() &&
    window.matchMedia &&
    window.matchMedia("(display-mode: standalone)").matches
  );
}

function scriptHasBeenLoaded(url) {
  if (!scriptHasBeenAdded(url)) return false;

  const scriptIsOnLoadMap =
    window.script_load_map && window.script_load_map[url];
  if (!scriptIsOnLoadMap) return true;

  return false;
}

function scriptHasBeenAdded(url) {
  if (!process.browser) return false;

  const scripts = document.getElementsByTagName("script");
  for (let i = scripts.length; i--; ) {
    if (scripts[i].src == url) return true;
  }
  return false;
}

function viewportWidth() {
  if (isServer()) return null;
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function isLoaded(variable) {
  if (variable == "gtag") {
    return typeof window.gtag !== "undefined";
  }
}

function fromEntries(iterable) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
}
