import { request } from "utils/api";
import { RequestResponse } from "utils/base_api";

import { isPwa } from "utils/browser";

interface User {
  name?: string;
  email?: string;
  password?: string;
  passwordConfirmation?: string;
  profile?: {
    currentStudy?: string;
    age?: string;
    desiredInstitution?: string;
    isTester?: string;
  };
}

export function create(params: User): Promise<RequestResponse> {
  return request("/user", { method: "POST", body: params });
}

export function update(params: User): Promise<RequestResponse> {
  return request("/user", { method: "PATCH", body: params });
}

export function show(opts = {}): Promise<RequestResponse> {
  return request(`/user?skip_pwa_notifications=${isPwa()}`, {
    ...opts,
    method: "GET"
  });
}

export function confirmEmail(
  email: string,
  code: string
): Promise<RequestResponse> {
  return request("/confirm_email", {
    method: "POST",
    body: { email, code }
  });
}
